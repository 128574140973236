import { Stack, Typography, SxProps } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
  description: string;
  sx?: SxProps;
};

export default function IntroHead({ title, description, sx }: Props) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={sx}
    >
      <Typography component="h2" variant="h2" textAlign="center">
        {title}
      </Typography>
      <Typography gutterBottom textAlign="center" color="text.secondary">
        {description}
      </Typography>
    </Stack>
  );
}
